import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';
import NecoFarming from '@/common/contracts/abis/neco-farming';
import {
  getLPTokenContract,
  getLPTotalSupply,
  getReserves,
} from '@/common/contracts/apis/lp-token-api';

export function getFarmingContract(web3: Web3 | null, address: string): Contract | null {
  if (web3 === null) {
    return null;
  }
  return new web3.eth.Contract(NecoFarming.ABI, address);
}

export async function getInitialSupply(web3: Web3, address: string): Promise<string> {
  const contract = getFarmingContract(web3, address);
  if (contract === null) {
    return '0';
  }
  return contract.methods.initReward().call();
}

export async function getHalvingDuration(web3: Web3, address: string): Promise<string> {
  const contract = getFarmingContract(web3, address);
  if (contract === null) {
    return '0';
  }
  return contract.methods.DURATION().call();
}

// 获得质押的总数量
export async function getTotalStaked(web3: Web3, address: string): Promise<string> {
  const contract = getFarmingContract(web3, address);
  if (contract === null) {
    return '0';
  }
  return contract.methods.totalSupply().call();
}

// 获得当前用户的质押数量
export async function getStakedOfAccount(
  web3: Web3, address: string, account: string,
): Promise<string> {
  const contract = getFarmingContract(web3, address);
  if (contract === null) {
    return '0';
  }
  return contract.methods.balanceOf(account).call();
}

// 得到当前用户可以收取的数量
export async function getClaimedOfAccount(
  web3: Web3, address: string, account: string,
): Promise<string> {
  const contract = getFarmingContract(web3, address);
  if (contract === null) {
    return '0';
  }
  return contract.methods.getRewardsAmount(account).call();
}

// 质押代币
export async function stakeToken(
  web3: Web3,
  address: string,
  account: string,
  amount: string,
): Promise<boolean> {
  const contract = getFarmingContract(web3, address);
  if (contract === null) {
    return false;
  }

  try {
    await contract.methods.stake(amount).send({ from: account });
    return true;
  } catch (error) {
    return false;
  }
}

// 提取我的质押币
export async function withdraw(
  web3: Web3,
  address: string,
  account: string,
  amount: string,
): Promise<boolean> {
  const contract = getFarmingContract(web3, address);
  if (contract === null) {
    return false;
  }
  try {
    await contract.methods.withdraw(amount).send({ from: account });
    return true;
  } catch (error) {
    return false;
  }
}

// 提取我的NECO币
export async function claim(
  web3: Web3,
  address: string,
  account: string,
  amount: string,
): Promise<boolean> {
  const contract = getFarmingContract(web3, address);
  console.log(amount);
  if (contract === null) {
    return false;
  }
  try {
    await contract.methods.claim(amount).send({ from: account });
    return true;
  } catch (error) {
    return false;
  }
}

// 获取减半的时间
export async function halvingTime(web3: Web3, address: string): Promise<number> {
  const contract = getFarmingContract(web3, address);
  if (contract === null) {
    return 0;
  }
  return contract.methods.halvingTime().call();
}

export async function rewardRate(web3: Web3, address: string): Promise<string> {
  const contract = getFarmingContract(web3, address);
  if (contract === null) {
    return '0';
  }
  return contract.methods.rewardRate().call();
}

export async function calculateApy(
  web3: Web3, address: string, isLp: boolean, tokenAddress: string,
): Promise<string> {
  const totalDistribution = await getInitialSupply(web3, address);
  const duration = Number(await getHalvingDuration(web3, address)) / 3600 / 24;
  const totalStaked = await getTotalStaked(web3, address);

  if (isLp) {
    const lpContract = getLPTokenContract(web3, tokenAddress);
    if (lpContract !== null) {
      const lpTotalSupply = await getLPTotalSupply(lpContract);
      const reverse = await getReserves(lpContract);
      const totalTokenNumber = `${((Number(totalStaked) / Number(lpTotalSupply)) * Number(reverse[1])) * 2}`;
      const apyNumber = (((Number(totalDistribution))
        / duration) / Number(totalTokenNumber)) * 365;
      return `${(apyNumber * 100).toFixed(2)}`;
    }
    return '0';
  }

  const apyNumber = (Number(totalDistribution)
      / duration / Number(totalStaked)) * 365;
  const apy = (apyNumber * 100).toFixed(2);
  return `${apy}`;
}
