import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';
import LPToken from '@/common/contracts/abis/lp-token';

export function getLPTokenContract(web3: Web3 | null, address: string): Contract | null {
  if (web3 === null) {
    return null;
  }
  return new web3.eth.Contract(LPToken.ABI, address);
}

export async function getReserves(contract: Contract): Promise<any> {
  return contract.methods.getReserves()
    .call();
}

export async function getLPTotalSupply(contract: Contract): Promise<string> {
  return contract.methods.totalSupply()
    .call();
}
